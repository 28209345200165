// Copyright 2021-2022 Epic Systems Corporation

@import "./theme/_branding.scss";
@import "./theme/_myChartColors.scss";

/// BEFORE DEFINING A NEW COLOR HERE:
/// Check for an existing MyChart color definition (http://rocinante.epic.com/infrastructure/sassdoc).
/// If an existing color isn't used by EVC yet, add it to _myChartColors.scss.

/// EVC COMPONENTS
/// Colors that can be overridden by branding should be defined in ./theme/_branding.scss
/// Other "standard" EVC defined colors, should be defined here and prefixed with $EVCColor-

$EVCColor-NotificationBack: #ffa020;
$EVCColor-ScreenSharePreviewBorder: red;
$EVCColor-SelectorFore: $WPColor-TextBody;
$EVCColor-SelectorForeOnColoredBack: $WPColor-TextOnColoredBack;
$EVCColor-SelectorBackgroundSelected: $_BasicColor-Whitesmoke;

/// OLD COLORS
/// If you are going to reuse one of the below colors, instead move them above and define a standard color

// colors used for dropdown menus
$menu-border-color: #444;

// alert button colors
$alert-negative-red: #d9545b;
$alert-neutral-blue: #0091ea;
$alert-positive-green: #09aa71;

// colors used for toasts
$toast-transparent-color: rgba(68, 68, 68, 0.6);
$toast-solid-color: #303030;
$toast-dismiss-button-color: #222;

// colors used by negative buttons
$negative-button-color: #94050c;
$negative-button-color-active: #a5161d;
$negative-button-border-color-active: #c7383f;

// general action button colors (used by toasts)
$positive-button-color: #0f784a;
$neutral-button-color: #246fb5;

// Secondary button colors
$secondary-button-blue: rgb(0, 145, 234);
$secondary-button-red: #d9545b;
$secondary-button-neutral: white;

// colors used by join call button
$joinCall-green: #00851f;
$joinCall-blue: #526ecc;
$joinCall-yellow: #ffc000;

// colors for image capture buttons
$retake-blue: $secondary-button-blue;
$save-green: $joinCall-green;
$discard-red: $secondary-button-red;

// colors for toggle switches
$toggle-green: #03ab70;
$toggle-gray: #cccccc;

// colors shared between grid resources
$placeholder-video-text: white;

// colors for modals
$modal-background: rgba(0, 0, 0, 0.5);

// colors for carousel
$carousel-blue: #0098cf;

// colors for cards
$card-title-color: #246fb5;
