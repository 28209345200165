@import "../Participants/_pinning.scss";
@import "../../styles/_colors.scss";
@import "../../styles/_utilities.scss";
@import "../../styles/_dimensions.scss";
@import "../../styles/theme/branding.scss";
@import "../Header/Menu/_menuUtils.scss";

$menu-width-overlay: 100%;
$small-sidebar-width: 300px;

.sidebarOpen {
	background: $EVCColor-MenuBack;
	pointer-events: auto;
	position: relative;
	right: 0;
	top: 0;
	height: 100%;
	flex: 0 0 $menu-width;
	overflow: hidden;
	display: flex;
}

.sidebarClose {
	display: none;
	flex: 0 0 $menu-width;
	min-width: $menu-width;
}

//For any screens that would place the participants in the strip, switch to a full-screen menu
@media only screen and (max-width: $screen-width-inflection),
	only screen and (max-height: $minimum-screen-size-for-header) {
	.sidebarOpen {
		flex: 0 0 $menu-width-overlay;
	}
	.sidebarClose {
		min-width: $menu-width-overlay;
		flex: 0 0 $menu-width-overlay;
	}
}

//For any screens that aren't small enough to be fullscreen, but are small enough that they don't need fullsized chat
//Make the chat only take up 300px
@media only screen and (max-width: 1000px) and (min-width: calc($screen-width-inflection + 1px)) and (min-height: calc($minimum-screen-size-for-header + 1px)) {
	.sidebarOpen {
		flex: 0 0 $small-sidebar-width;
	}
	.sidebarClose {
		min-width: $small-sidebar-width;
		flex: 0 0 $small-sidebar-width;
	}
}
