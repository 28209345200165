// Copyright 2021 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_dimensions.scss" as *;
@use "../../styles/_utilities.scss" as *;

$space-between-buttons: 5px;

.buttonGrouping {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 2 * $space-between-buttons;

	&:not(:last-child) {
		padding-right: 2 * $space-between-buttons;
		border-right: solid #888888 1px;
	}
}

.controlButton {
	background-color: $EVCColor-HeaderButtonBack;
	color: $EVCColor-HeaderButtonFore;
	border: 1px solid $EVCColor-HeaderButtonBack;

	@include focusedOrHovered() {
		background-color: $EVCColor-HeaderButtonHoverBack;
		border-color: $EVCColor-HeaderButtonHoverBorder;
		outline: none;
	}

	&:not(:last-child) {
		margin-right: $space-between-buttons;
	}

	&.negative {
		background-color: $WPColor-ActionButtonNegativeBack;
		border-color: $WPColor-ActionButtonNegativeBack;

		@include focusedOrHovered() {
			background-color: $WPColor-ActionButtonNegativeHoverBack;
			border-color: $WPColor-ActionButtonNegativeHoverBack;
		}
	}
}

.controlButton.locked {
	svg {
		height: 30px;
		width: 30px;
	}
}

.controlButton.deviceDisabled {
	svg {
		fill: red;
	}
}

.controlButton.minimized {
	@media only screen and (min-width: $screen-width-inflection) and (min-height: $minimum-screen-size-for-header) {
		// Set defaults for the controls header, the width and height constrained classes will account for these otherwise.
		height: 30px;
		width: 30px;

		svg {
			height: 20px;
			width: 20px;
		}
	}
}

.controlButton.default {
	svg {
		fill: $EVCColor-HeaderButtonFore;
		stroke: $EVCColor-HeaderButtonFore;
	}
}

.controlButton.buttonDisabled {
	cursor: default;
	svg {
		fill: $WPColor-ActionButtonDisabledFore;
		stroke: $WPColor-ActionButtonDisabledFore;
	}
}

.toggleChevron:not(.collapsed) svg {
	transform: unset; // undo transform from icons/chevron.module.scss
}

.notification {
	& > div:before {
		position: absolute;
		bottom: 75%;
		left: 75%;
		height: 10px;
		width: 10px;
		border-radius: 100%;
		background: $EVCColor-NotificationBack;
		content: "";
		@media only screen and (max-width: $screen-width-inflection),
			(max-height: $minimum-screen-size-for-header) {
			bottom: 75%;
			left: 75%;
		}
	}
	&.toggleChevron:is(.collapsed) {
		& > div:before {
			position: absolute;
			bottom: 75%;
			left: 75%;
			height: 10px;
			width: 10px;
			border-radius: 100%;
			background: $EVCColor-NotificationBack;
			content: "";
			@media only screen and (max-width: $screen-width-inflection),
				(max-height: $minimum-screen-size-for-header) {
				bottom: 75%;
				left: 75%;
			}
		}
	}
}

.screenReaderOnly {
	position: absolute;
	width: 0;
	height: 0;
	clip: rect(0, 0, 0, 0);
}

.soloMenu {
	@media only screen and (min-width: $screen-width-inflection) {
		margin-top: 14px;
	}
	z-index: 10;
}

.buttonActive {
	& > div:after {
		position: absolute;
		bottom: 0px;
		left: 2px;
		height: 3px;
		width: 30px;
		background: $WPColor-BrandAccentBack;
		content: "";
	}

	.buttonTray & > div:after {
		bottom: 8px;
		left: 50%;
		transform: translate(-50%);
	}
}

.trays {
	position: relative;
	z-index: -1; // force the mobile button tray behind the header so the shadow isn't on top of the header
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: baseline;
	pointer-events: none;
}

.trayAndToastsWrapper {
	width: 100%;
	box-shadow: 0 0 15px 2px;
}

.buttonTray {
	max-height: 55px;
	width: 100%;
	display: flex;
	overflow: hidden;
	box-shadow: 0 0 15px 2px;
	pointer-events: auto;

	.controlButton {
		flex-grow: 1;
		border-radius: 0;
		margin: 0;

		svg {
			margin: 10px;
		}
	}
}

.moreOptionsMenu {
	list-style-type: none; /* Remove bullet points */
	padding: 0; /* Remove default padding */
	margin: 0; /* Remove default margin */

	.controlButton {
		width: 100%;
		background-color: $EVCColor-MenuBack;
		border-color: $EVCColor-MenuBack;

		& > div {
			width: 100%;
			justify-content: space-between;
		}

		@include focusedOrHovered() {
			background-color: $EVCColor-MenuButtonHoverBack;
			border-color: $EVCColor-MenuButtonHoverBorder;
			outline: none;
		}

		svg {
			margin: 0px;
		}
	}
}

.moreMenuWrapper {
	flex-grow: 1;
	display: flex;

	.controlButton {
		margin-right: 0;
	}

	&:not(:last-child) {
		margin-right: $space-between-buttons;
	}

	.buttonTray & {
		margin-right: 0;
	}
}

// Control buttons start to go off the screen here (mobile with text scaling turned way up)
@media only screen and (max-width: 272px) {
	.buttonTray {
		max-height: 34px; // 22px (icon height) + 10px (margins) + 2px (button border)

		.controlButton {
			padding: 0;

			svg {
				margin: 5px;
				height: 22px;
				width: 22px;
			}
		}
	}

	// Too small for the reduced-size buttons to fit on one line, we need to wrap
	// Should only happen on iPhone 5/SE at 200%, or similar
	@media (max-width: 169px) {
		.buttonTray {
			display: flex;
			flex-wrap: wrap;
			max-height: 68px;

			.controlButton {
				padding: 0px 10px; // This gets the buttons to wrap into a 2x2
			}

			// Reduce padding as screen gets narrower, to keep it on 2 lines exactly
			@media (max-width: 135px) {
				.controlButton {
					padding: 0 6px;
				}
			}

			@media (max-width: 115px) {
				.controlButton {
					padding: 0 2px;
				}
			}
			@media (max-width: 101px) {
				.controlButton {
					padding: 0;
				}
			}

			// At 84px width, the buttons can't even fit two across, but that's too small to expect anything to work right
		}
	}
}
