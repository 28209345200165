// Copyright 2020 Epic Systems Corporation

@import "../../styles/_colors.scss";
@import "../../styles/_dimensions.scss";

.backgroundSelector {
	display: flex;
	flex-direction: column;
	background: $EVCColor-MenuBack;
	color: white;
	border-top: none;
	font-size: 16px;
	padding: 0 0 10px 0;
	align-items: center;

	// Add some vertical spacing to spinner elements
	> span {
		margin: 10px 0;
	}
}
