// Copyright 2024 Epic Systems Corporation

.testNetworkDiv {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0 /*!rtl:ignore*/;
	right: 0 /*!rtl:ignore*/;
	margin: unset;
	justify-content: center;
	overflow: auto;
	width: 100%;
	padding: 15px;
	text-align: left;
	ul {
		li {
			text-align: left;
			margin-bottom: 10px;
		}
		list-style-type: none;
		padding: 0;
		margin: 0 15px 0 15px;
	}
}
