// Copyright 2024 Epic Systems Corporation

.testPage {
	background-color: transparent;
	padding: 10px;

	h1,
	h2 {
		text-align: center;
	}

	button {
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}
